=post-card-image()
  border: none
  border-radius: 4px 4px 0 0
  height:     207px
  max-height: 207px

=post-card-author-photo()
  height: 32px
  width: 32px
  border-radius: 50px

=post-card-image__2-column($height)
  .posts__post-preview
    height: $height
    .posts__post-preview-image--regular
      height: $height
      max-height: $height
