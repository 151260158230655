@import '../variables'

.pagination
  display:          block
  position:         relative
  font-size:        12px
  text-align:       center
  clear:            both
  +mobile
    min-height:     60px
    padding:        0

.pagination__button--disabled
  color: $buttonSecondaryBackgroundColor
  opacity: 1
  &:hover, &:active, &:focus
    color: $buttonSecondaryBackgroundColor
    background-color: transparent
    cursor: not-allowed

.pagination__button
  display: inline-block
  text-decoration: none
  color: $grey300
  border-radius: 4px
  font: bold 12px/1.3 $bodyFont
  box-shadow: none
  &:hover, &:active, &:focus
    color: $defaultColor
    background-color: $grey50
  height: 30px
  line-height: 30px
  min-width: 30px
  padding: 0px 10px
  margin: 0

.pagination__prev-button,
.pagination__next-button
  right: 0
  margin: 0 5px
  color: $white
  background-color: $grey700
  .fa
    font-weight: bold
    font-size: 16px

.pagination__page--current
  font-weight: bold
  color: #444444
  background-color: $grey50
  margin: 0 5px
  &:hover, &:active, &:focus
    color: $grey25
    cursor: pointer
    background-color: darken($grey50, 10%)

.pagination__gap
  margin: 0 5px
