@import '../variables'
@import '../mixins/clearfix'
@import '../mixins/block-button'
@import 'qrcode'

.post
  @include pie-clearfix
  margin-top: 40px
  position: relative
  +mobile
    border-top: none
    margin-top: 24px

.post .layout__content-with-sidebar
  +wide
    max-width: $contentMaxWidescreenWidth

.post .avert
  margin-bottom: $contentSectionMarginBottom

.post__featured-image
  max-width: 100%
  width: 100%

.post__inline-ad
  float: right
  margin-left: $column-width

.post__sidebar
  @include pie-clearfix

.post__sidebar-primary
  +tablet
    width: $three-columns-wide
    float: left

.envato-sticky-ad-img
  width: $three-columns-wide

.envato-sticky-ad
  margin-top: 70px

.post__sidebar-resource-links
  a
    @include block-button
    margin-bottom: 10px
    &:last-child
      margin-bottom: $contentSectionMarginBottom

.post__sidebar-secondary
  +tablet
    width: $three-columns-wide
    float: right

.post__footer
  @include pie-clearfix
  border-top: solid 1px $borderColor
  margin-top:  40px
  padding-top: 40px

.post__comments
  margin-bottom: 40px

.category_sidebar_ad
  +mobile
    display: none

.category_sidebar_ad_mobile
  +non-mobile
    display: none

// The secondary sidebar will be converted to a floating sticky element.
// We need to ensure there is enough space on smaller articles.

.sticky-ad-target
  +all-desktop
    min-height: 960px

+mobile-and-tablet
  .sticky-ad.tether-enabled
    display: none !important

.sticky-ad-non-mobile
  +mobile
    display: none

.sticky-ad-mobile
  +non-mobile
    display: none
