@import '../variables'

.fa-weixin
  color: #00cd00

img.qrcode
  width: 100px
  height: 100px

.qrcode .tooltip
  position: relative
  display: inline-block
  cursor: pointer

.qrcode  .tooltip .tooltip-content
  visibility: hidden
  background-color: $white
  border: 1px solid $borderColor
  color: black
  text-align: center
  border-radius: 5px
  padding: 10px
  position: absolute
  z-index: 1
  bottom: 150%
  left: 50%
  margin-left: -60px
  line-height: 14px
  font-size: 12px
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175)
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175)


.qrcode .tooltip:hover .tooltip-content
  visibility: visible
