@import '../mixins/responsive'
@import '../mixins/clearfix'
@import '../mixins/h1'
@import '../mixins/anchor'
@import '../variables'

.category-header-test-control-without-header
  display: block

.category-header__breadcrumbs-title
  background-color: transparent
  margin-bottom: 2rem

  + .category_featured_content
    margin-top: 40px

.category-header-tags
  background-color: transparent

.content-header-image-restricted-height
  margin-top: -3rem
  +mobile
    margin-top: 0
    height: $categoryHeaderImageMaxMobileHeight
  +tablet
    height: $categoryHeaderImageMaxNonMobileHeight
  +wide
    height: $categoryHeaderImageMaxNonMobileHeight
  +desktop
    height: $categoryHeaderImageMaxNonMobileHeight
  .content-banner__title
    padding-top: 4rem

.content-banner-restricted-width
  +desktop
    max-width: $contentMaxDesktopWidth
  +wide
    max-width: $contentMaxWidescreenWidth

.content-banner-bottom
  padding-bottom: 40px
  margin-bottom: 10px
  &.no-bottom-padding
    padding-bottom: 0px

.content-banner__icon, .content-banner__body
  display: table-cell

.content-banner__title
  color: $newBodyTextColor
  margin: 0

  @include h1-style

.content-banner__title--rtl
  direction: rtl

.content-banner__title-icon
  width:  46px
  height: 36px
  padding-right: 10px
  +mobile
    display: none

.content-banner__description
  color: $newSecondaryBodyTextColour
  font-size: 18px
  padding-top: 10px
  line-height: 26px
  margin-bottom: 40px
  +mobile
    padding-top: 5px
    margin-bottom: 10px
  &.introductory-text
    #introductory-text__content
      margin-top: 1em
      &.show-less
        max-height: 100px
        overflow: hidden
        transition: max-height 0.35s ease-out
      &.show-full
        transition: max-height 0.35s ease-in !important
      p:first-child
        margin-top: 0px
      p:last-child
        margin-bottom: 0px
    #introductory-text__links-divider
      background: linear-gradient( to bottom, rgba(255, 255, 255, 0), hsl(0, 0%, 100%) 75% )
      height: 60px
      margin-top: -60px
      position: relative
    #introductory-text__read-more-link, #introductory-text__read-less-link
      display: none
      cursor: pointer
      color: $primaryBlueColor
      font-weight: 600
      &:hover, &:active, &:focus
        color: darken($primaryBlueColor, 5%)

    #introductory-text__read-less-link
      margin-top: 15px
    .show
      display: inline-block !important
      
    +anchor-simple-link-style  

.category-heading-intro-wrapper
  max-width: 700px

  .content-banner__description
    color: $newBodyTextColor
    font-size: 14px
    line-height: 21px
    padding-top: 8px
    margin-bottom: 0px
    +mobile
      padding-top: 8px
      margin-bottom: 0px

    #introductory-text__content
      margin-top: 0
      -webkit-mask-image: linear-gradient(to top, transparent 0%, transparent 1rem, rgb(0, 0, 0) 0rem, rgb(0, 0, 0) 100%), linear-gradient(to right, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 80%, transparent 100%, transparent 100%)
      &.show-less
        &:not(.show-full)
          max-height: 42px !important

    #introductory-text__links-divider
      display: none

    .introductory-text-links
      #introductory-text__read-more-link
        float: right
        margin-top: -21px
        background-color: $white
        position: relative
        box-shadow: -10px 0px 5px -2px $white

      #introductory-text__read-less-link
        margin-top: 0px

.category-heading-hero-image-wrapper
  max-width: 400px

.content-banner__content-breadcrumb
  margin-top: 0px
  margin-bottom: 16px
  list-style: none
  padding-left: 0
  font-size: 14px

  +mobile
    margin-top: 24px

  @include clearfix

  li
    float: left
    display: inline-block
    color: $breadcrumbTextColor

    &:last-child
      color: $breadcrumbHighlightTextColor

    a
      color: inherit
      &:hover
        text-decoration: underline

  li+li
    &:before
      padding-left: 10px
      padding-right: 5px
      content: "\f105"
      font-family: "Font Awesome 5 Free"
      font-weight: 900
      color: $newSecondaryBodyTextColour

  .breadcrumbs-link-item__home
    +non-desktop
      display: none

    &:before
      display: none

  .breadcrumbs-link-item__home-icon
    display: none
    +non-desktop
      display: inline-block

    img
      margin-bottom: -1px

.content-banner__title-breadcrumb
  margin-top:    10px
  margin-bottom: 60px

.content-banner__title-breadcrumb-link
  color: $newSecondaryBodyTextColour
  font-weight: normal
  &:hover, &:active, &:focus
    color: $newSecondaryBodyTextColour
    text-decoration: underline

.content-banner__title-breadcrumb-separator
  padding-left: 20px
  padding-right: 20px
  font-size: 0.6em
  vertical-align: middle
  color: $newSecondaryBodyTextColour

.content-banner__topic-home
  max-width: $nine-columns-wide

.content-banner__content-breadcrumb-with-primary-category
  margin-bottom: 8px !important

  li

    &:last-child
      color: $breadcrumbTextColor !important

.content-banner__summary
  color: $grey700
  font-size: 24px
  font-style: normal
  font-weight: 400
  line-height: 150%
  letter-spacing: 0.36px
  margin-top: 24px

  +mobile
    font-size: 20px
    margin-top: 16px