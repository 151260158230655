@import '../variables'
@import '../mixins/clearfix'
@import '../mixins/responsive'


.topic-home__learning-guides__blurb
  margin-bottom: 20px
  font-size: 18px
  color: $newSecondaryTextColor

.topic-home__featured-content
  margin-top: 20px
  border-top: 1px solid #ccc

.topic-home__featured-content__blurb
  margin-bottom: 20px
  font-size: 18px
  color: $newSecondaryTextColor

.topic-home__learning-guides__footer
  margin-top: 20px
  text-align: right
  a
    color:          $primaryBlueColor
    font:           bold 14px/1.6em $font
    white-space:    nowrap
    &:hover, &:active, &:focus
      color: $linkHoverColor
    &:after
      margin-left: 5px
      content: $rightArrow

.topic-home__featured-content__footer
  a
    border-right: 1px $grey solid
    margin-right: 10px
    padding-right: 10px
    color:          $primaryBlueColor
    font:           bold 14px/1.6em $font
    &:hover, &:active, &:focus
      color: $linkHoverColor
    &:after
      margin-left: 5px
      content: $rightArrow
    &:last-child
      border-right: none
      padding-right: 0

.topic-home__featured-content__posts
  +clearfix
