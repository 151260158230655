@mixin anchor-link-style
  a:not([class])
    +anchor-bold-style

@mixin anchor-bold-style
  color: $primaryBlueColor
  font-weight: 600
  &:hover, &:active, &:focus
    color: darken($primaryBlueColor, 5%)
  &:hover, &:focus
    text-decoration: underline

@mixin anchor-default-link-style
  color: $primaryBlueColor
  font-weight: inherit
  text-decoration: underline
  text-underline-position: under
  text-decoration-color: lighten($primaryBlueColor, 45%)
  &:hover
    text-decoration-color: $primaryBlueColor
  &:visited
    color: $violet600
    text-decoration-color: $violet25
  &:visited:hover
    text-decoration-color: $violet600

@mixin anchor-simple-link-style
  a:not([class])
    color: inherit
    font-weight: inherit
    text-decoration: underline
    text-underline-position: under
    text-decoration-color: inherit
    &:hover
      color: $primaryBlueColor
      text-decoration-color: $primaryBlueColor

@mixin anchor-naked-link-style
  a:not([class])
    color: inherit
    font-weight: 600
    &:hover
      text-decoration: underline
      text-decoration-color:  $darkGrey


