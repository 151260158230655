@import '../variables'

@mixin block-button($background: $primaryBlueColor, $foreground: $white)
  display: block
  background: $background
  color: $foreground
  border-radius: 4px
  padding: 8px 0 8px
  vertical-align: middle
  text-align: center
  cursor: pointer
  white-space: nowrap
  &:hover, &:active, &:focus
    background: darken($background, 5%)
    color: $foreground
