@import '../variables'
@import '../mixins/clearfix'

.syllabuses
  list-style:   none
  padding:      0
  margin:       0
  +clearfix

.syllabuses__syllabus-preview-image--regular
  border: solid 1px $grey100

+non-mobile
  //----------------------------------------------------------------------
  // List view
  //----------------------------------------------------------------------
  .syllabuses--list
    margin:         0 auto 20px

    .syllabuses__syllabus
      position: relative
      margin: 0
      min-height: 279px
      border-bottom: solid 1px $borderColor
      padding: 35px 0 35px ($three-columns-wide + $gutter-width)
      +tablet
        min-height: auto
        padding-left: $two-columns-wide + $gutter-width

      &:last-child
        border-bottom: none

    .syllabuses__syllabus-preview
      width: $three-columns-wide
      +tablet
        width: $two-columns-wide

      overflow: hidden
      position: absolute
      left: 0
      top: 35px

    .syllabuses__syllabus-preview-image
      width: 100%

    .syllabuses__syllabus-header
      margin-top: -5px

    .syllabuses__syllabus-title
      color: $headingTextColor
      margin-bottom: 0
      margin-right: 10px
    .syllabuses__syllabus-title h1
      display: inline
      font: bold 22px/1.2em $headingFont
      margin-top: 0
      margin-bottom: 0

    .syllabuses__syllabus-posts-count
      display: inline-block
      font-size: 12px
      color: $postPublicationMetaColor

    .syllabuses__syllabus-summary
      font: 14px/1.6em $bodyFont
      color: $grey700
      margin-top: 10px

      figure, img, embed, video, object, iframe
        max-width: 100%

      img
        // This *may* have adverse effect if applied to object/iframe. (?)
        height: auto

      code, tt, pre
        font-family: $sfMonoFont

      pre
        overflow: hidden

      hr
        display: none


    .syllabuses__syllabus-read-more-link
      color: $primaryBlueColor
      font-size: 12px
      font-weight: bold
      display: inline-block
      margin-top: 5px
      &:hover, &:active, &:focus
        color: darken($primaryBlueColor, 10%)

  //----------------------------------------------------------------------
  // Grid view
  //----------------------------------------------------------------------
  .syllabuses--grid

    .syllabuses__syllabus
      +non-mobile
        float: left
        width: $two-columns-wide
        margin-right: $gutter-width
        &:nth-child(3n)
          margin-right: 0
      +wide
        width: $three-columns-wide
      +tablet
      position:       relative
      overflow:       hidden

    .syllabuses__syllabus-preview
      overflow: hidden

    .syllabuses__syllabus-preview-image
      width: 100%

    .syllabuses__syllabus-header
      display:        block
      padding-top: 10px

    .syllabuses__syllabus-title
      display:        block
      color:          $headingTextColor
      margin-bottom:  3px
    .syllabuses__syllabus-title h1
      font:           bold 17px/1.2em $headingFont
      margin-bottom: 0
      margin-top: 0


    .syllabuses__syllabus:hover
      .syllabuses__syllabus-title
        color: darken($headingTextColor, 10%)

    .syllabuses__syllabus-posts-count
      display:        block
      color:          $grey300
      font:           12px/1.2em $bodyFont

    .syllabuses__syllabus-summary,
    .syllabuses__syllabus-read-more-link
      display:      none

//----------------------------------------------------------------------
// Mobile view
//----------------------------------------------------------------------
+mobile
  .syllabuses__syllabus
    border-bottom:  solid 1px $borderColor
    overflow: hidden

  .syllabuses__syllabus-preview
    overflow: hidden
    width:            76px
    float:            left
    margin:           9px 0

  .syllabuses__syllabus-preview-image
    width: 100%
    margin: 0

  .syllabuses__syllabus-details
    margin-left:      93px
    margin-top:       15px
    font:             12px/1.6em $font

  .syllabuses__syllabus-header
    left:         11px
    bottom:       9px

  .syllabuses__syllabus-title h1
    font:         bold 17px/1em $headingFont
    color:        $headingTextColor

  .syllabuses__syllabus-posts-count
    font-size:    12px
    line-height:  1em
    color:        $metaColor

  .syllabuses__syllabus-summary,
  .syllabuses__syllabus-read-more-link
    display:      none

.syllabuses__syllabus-preview-image
  aspect-ratio: $previewImageAspectRatio