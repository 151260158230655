@import '../variables'
@import '../mixins/three-column-card'
@import '../mixins/post-card'

@mixin post-spacer
  margin-left: 6px
  white-space: nowrap

  &:before
    content: "•"
    display: inline-block
    padding-right: 6px

.category_featured_content
  +mobile-and-tablet
    display: none

.category_featured_content_mobile_and_tablet
  +desktop
    display: none
  +wide
    display: none

.posts
  list-style: none
  margin: 0
  padding: 0

.posts__post
  display:  block
  position: relative
  overflow: hidden
  article
    position: absolute
    width: 100%
    top: 0
    bottom: 0
    -webkit-transition: all 0.2s ease
    -moz-transition: all 0.2s ease
    -o-transition: all 0.2s ease
    -ms-transition: all 0.2s ease
    transition: all 0.2s ease
    +mobile-and-tablet
      position: relative
      height: 100%

.posts__post--rtl
  .posts__post-title--rtl, .posts__post-teaser
    direction: rtl
    text-align: right


.posts__post-preview
  overflow: hidden
  width: 100%
  text-align: center
  display: block
  margin: 0 auto
  border-bottom: 1px solid $grey100
  line-height: 0
  height: 207px

.posts__post-preview-image
  margin: 0

.posts__post-preview-image--regular
  width: 100%
  +post-card-image

.posts__post-primary-topic
  text-transform: uppercase
  +topic-colors
    color: $topic-color

.posts__post-title
  display:        block
  margin: 10px 15px 0
  max-height: 70px

.posts__post-title h1
  font: bold 18px/1.4em $headingFont
  color: $grey700
  margin-top: 0
  margin-bottom: 0
  display: -webkit-box
  -webkit-line-clamp: 3
  -webkit-box-orient: vertical
  overflow: hidden
  text-overflow: ellipsis

.posts__post-publication-date,
.posts__post-publication-meta
  color: $mediumGrey

.posts__post-publication-meta
  display: block
  background-color: $white
  width: 100%
  padding-top: 15px

.posts__post-details
  // color: $postPublicationMetaColor
  font-size: 12px
  position: absolute
  bottom: 5px
  left: 0
  width: 100%
  padding: 0 15px
  color: $linkSecondaryColor

.posts__post-language,
.posts__post-social-count
  @include post-spacer

.posts__post-extra-info
  position: absolute
  top: 31px
  right: 15px
  bottom: 0

.posts__post-primary-topic,
.posts__post-primary-category
  position: absolute
  top: 50px
  right: 13px
  z-index: 1

.posts__post-primary-topic-link,
.posts__post-primary-category-link
  font: bold 12px/1 $headingFont
  text-transform: uppercase
  display: inline-block
  -webkit-font-smoothing: subpixel-antialiased
  color: $grey
  +topic-colors
    color: $topic-color
  &:hover, &:active, &:focus
    +topic-colors
      color: darken($topic-color, 5%)

.posts__post-author-link
  display: inline-block
  max-width: 175px
  vertical-align: middle
  color: $mediumGrey
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

html.no-touch
  .posts__post
    .posts__post-preview:hover
      // Don't do this on .posts__post-preview-image because it has a transition on opacity,
      // it has a weird image width resizing issue when going from 1 to less than 1.
      opacity: 0.8
    .posts__post-title:hover
      color: darken($headingTextColor, 30%)
      text-decoration: underline

.posts__post-publication-meta,
.posts__post-author
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.posts__post-author
  display: block
  font-style: normal
  line-height: 1em

.posts__post
  @include three-column-card
  margin-bottom: 40px
  height: 463px
  article
    border: 1px solid $grey50
    border-radius: 4px
    margin-top: 13px
    height: 450px

.posts__post-author_photo
  +post-card-author-photo

.posts--with-sidebar
  .posts__post
    +wide
      &:nth-child(3n)
        margin-right: 0
    +desktop
      &:nth-child(2n)
        margin-right: 0
    +tablet
      &:nth-child(2n)
        margin-right: 0

.posts--with-center-column
  .posts__post
    +wide
      &:nth-child(3n)
        margin-right: 0
    +desktop
      &:nth-child(3n)
        margin-right: 0
    +tablet
      &:nth-child(2n)
        margin-right: 0

.posts--full-width
  .posts__post
    +wide
      &:nth-child(4n)
        margin-right: 0
    +desktop
      &:nth-child(3n)
        margin-right: 0
    +tablet
      &:nth-child(2n)
        margin-right: 0

.posts--half-width
  .posts__post
    +wide
      &:nth-child(2n)
        margin-right: 0
    +desktop
      &:nth-child(2n)
        margin-right: 0
    +tablet
      &:nth-child(2n)
        margin-right: 0

.category-home__4-column__featured-content__posts
  .posts__post
    margin-right: 30px !important
    &:last-child
      margin-right: 0 !important
      margin-bottom: 80px
    +desktop
      &:nth-child(4n)
        display: none
      &:nth-child(3n)
        margin-right: 0 !important
    +tablet
      display: none
    +mobile
      display: none

ol.content-results__with-filters
  & > li
    +wide
      &:nth-child(3n)
        margin-right: 0 !important
    +desktop
      &:nth-child(2n)
        margin-right: 0 !important

ol.content-results__without-filters
  & > li
    +wide
      &:nth-child(4n)
        margin-right: 0 !important
    +desktop
      &:nth-child(3n)
        margin-right: 0 !important
    +tablet
      &:nth-child(2n)
        margin-right: 0 !important

.hide
  display: none !important

.collapsible-section-content__max-height
  max-height: 128px

.posts--standalone
  .posts__post
    margin: 30px auto 10px auto
    float: none
    background-color: $white

.posts__post-teaser
  width: 100%
  font-size: 14px
  color: $mediumGrey
  line-height: 1.5em
  text-align: left
  padding: 9px 15px 5px 15px
  position: absolute
  top: 285px
  +mobile-and-tablet
    padding: 9px 15px 70px 15px

.posts__post-teaser-overlay
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+)
  background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)
  background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%)
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%)
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 )
  display: block
  height: 20px
  width: 100%

.posts__post-details__info
  display: inline-block
  padding-left: 5px
