@import '../variables'

.content-title__title
  color: $grey500
  font: lighter 49px/1.3em $bodyFont
  margin-top: 15px
  display: inline-block
  +mobile-and-tablet
    font-size: 40px
    line-height: 1.5em
    margin-bottom: 0
    margin-top: 0


.content-title__title-search-terms
  font-weight: bold
  color: #4D4D4D

.content-title__subtitle
  color: $grey500
  font: normal 16px/1.3em $bodyFont
  line-height: 1.3em
  display: inline
  padding-top: 0.2em

.multi-search__switcher-levels,
.multi-search__switcher-orders
  margin-bottom: 5px

.content-title--section-switcher-list
  display: flex
  width: 100%
  margin: 0
  list-style: none
  border-bottom: solid 1px $grey50
  margin-bottom: 20px
  padding: 0
  font: normal 20px/0.9em $bodyFont
  +mobile
    font: normal 14px/1.3em $bodyFont

.content-title--section-switcher-item
  display: block
  padding: 0
  a
    display: block

.content-title--section-switcher-item-link
  display: block
  padding: 15px 25px 30px
  color: $mediumGrey
  &:hover, &:active, &:focus
    color: #4D4D4D

.content-title--section-switcher-item-link__active
  display: block
  color: #4D4D4D
  border-bottom: solid 4px #4D4D4D
  font-weight: bolder
  +topic-colors
    border-bottom: solid 4px $topic-color
  &:hover, &:active, &:focus
    color: $newSecondaryBodyTextColour

.content-title--category-switcher-item-link__active
  display: block
  color: $grey700 !important
  border-bottom: solid 2px $grey700
  &:hover, &:active, &:focus
    color: $grey700 !important

.multi_search__topics_dropdown
  display: inline
  padding: 0 0.5em 0 0.5em

.multi_search__topics
  color: $newSecondaryBodyTextColour
  font: normal 16px/1.3em $bodyFont
  border: 1px solid $newSecondaryBodyTextColour
