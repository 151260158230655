@import '../../variables'
@import '../../mixins/clearfix'
@import '../../mixins/anchor'

.course
  margin-top: 40px
  +mobile
    margin-top: 10px
  +tablet
    margin-top: 30px
  @include pie-clearfix

.course .layout__content-with-sidebar
  max-width: $contentMaxWidescreenWidth

.course__sponsored-content
  +highlighted-box
  line-height: 1.6em

.course__description
  font-size: 18px
  color: $newBodyTextColor
  margin-bottom: $contentMarginTop
  p
    margin-top: 0
  a:not([class])
   +anchor-default-link-style

// TODO: get rid of this (in favour of extra-information below) after it's
// been removed from all project/course descriptions in Wistia.
.course__requirement-block
  background-color: #fff9d8
  padding: 16px 20px
  font-size: 14px
  line-height: 1.6em
  margin-bottom: 45px

.course__extra-information-block
  margin: 20px 0 40px
  background-color: $grey25
  padding: 16px 20px
  font: normal 14px/1.6em $bodyFont
  a:not([class])
    +anchor-default-link-style

.course__extra-information-block--warning
  background-color: #fff9d8

.course__extra-information-block-title
  font: bold 16px/1.2em $bodyFont
  margin-bottom: 16px

.course
  .lesson-index
    margin-bottom: 80px
    +mobile-and-tablet
      margin-bottom: 30px

.course__lesson-preview
  width: 100%
  min-height: 250px
  +non-mobile
    min-height: 420px
  overflow: hidden

.course__lesson-preview-label
  font: bold 16px $bodyFont
